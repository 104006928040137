<template>
  <div class="imgBox min-w-1400">
    <div class="imgBox1 min-w-1400">
      <div class="img1 max-w-1400">
        <img :src="productImg1" :alt="altText" class="imgItem1" />
      </div>
    </div>
    <!-- <div class="imgBox2 ">
      <div class="img2  max-w-1400">
        <img
          :src="productImg2"
          :alt="altText"          class="imgItem2"
        />
      </div> -->
    <!-- </div> -->
   
  </div>
</template>

<script>

import productImg1 from "@/page/mrVaymobile/components/img/4_1.png";
export default {
  data() {
    return {
      productImg1: productImg1,
      altText: "图片",
    };
  },
};
</script>

<style scoped>
.imgBox {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  overflow: hidden;
  background-color: white;
}
.imgBox1 {
  display: flex;
  justify-content: center;
  width: 100%;
  min-width: 1400px;
  background-color: "";

}
/* .imgBox2 {
  display: flex;
  justify-content: center;
  max-width: 1400px;
  padding-top: 40px;
  padding-bottom: 20px;
  min-width: 1400px;
  height: 3950px;
  background-color: #F8FEFF   ;
  border-radius: 32px;
  margin-top: -130px;
  margin-left: auto;
  margin-right: auto;
} */
img {
  display: block;
}
.img1 {
 width: 100%;
  overflow: hidden;
  max-width: 1620px;
  margin-left: 0;
  margin-right: 0;
}
.img2,
.img3,
.img4 {
  overflow: hidden;
}
.imgItem1 {
 width: 100%;
  overflow: hidden;
  max-width: 1620px;
  margin-left: 0;
  margin-right: 0;
}
.imgItem2 {
 max-width: 100%;
 
}
</style>
